@import "./SCSS/FonStyle.scss";
@import "./SCSS/Mixins.scss";

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: "JioType";
}

* {
  margin: 0;
  padding: 0;
  font-family: "JioType";
  font-style: normal;
  box-sizing: border-box;
}

.MuiGrid2-container {
  max-width: 1200px;
  // width: 100%;
}

.quoteWrapper {
  background-color: $lightbg;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 24px;
}

.quoteWrapperMobile {
  background-color: $white;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 60px;
  padding-bottom: 24px;
}

.quoteWrapper,
.quoteWrapperMobile,
.proposalWrapper,
.mProposalWrapper,
.proposalWrapper_m {
  .primaryBtn {
    .MuiButton-root {
      background-color: $primary;
      border: none;
      color: $warmgold;
      border-radius: 100px;
      font-weight: bold;
      letter-spacing: -0.08px;

      &.MuiButton-sizeLarge {
        @include fontSize(18px);
        padding: 16px 24px;
        height: 48px;
      }
      &.MuiButton-sizeMedium {
        height: 40px;
      }
      &.MuiButton-sizeSmall {
        padding: 4px 12px;
        @include fontSize(12px);
        height: 32px;
      }
      svg {
        fill: $warmgold;
      }
      &:hover {
        box-shadow: none;
        background-color: #fce0bd !important;
        color: #644920 !important;

        svg {
          fill: #644920;
        }
      }
      &:active {
        box-shadow: none;
        background-color: $darkgold;
      }
    }
  }
  .secondaryBtn {
    .MuiButton-root {
      color: $darkgold;
      border: none;
      outline: 1px solid #b5b5b5;
      border-radius: 100px;
      font-family: "JioType";
      letter-spacing: -0.08px;
      font-weight: bold;
      &.MuiButton-sizeLarge {
        @include fontSize(18px);
        padding: 16px 24px;
        height: 48px;
      }
      &.MuiButton-sizeMedium {
        height: 40px;
      }
      &.MuiButton-sizeSmall {
        padding: 4px 12px;
        @include fontSize(12px);
        height: 32px;
      }
      &:hover {
        box-shadow: none;
        background-color: #fce0bd !important;
        color: $darkgold !important;
      }
    }
  }
  .tertiaryBtn {
    .MuiButton-root {
      color: $darkgold;
      letter-spacing: -0.08px;
      font-weight: bold;
      &.MuiButton-sizeSmall {
        padding: 4px 12px;
        @include fontSize(12px);
        height: 32px;
      }
      &:hover {
        box-shadow: none;
        background-color: transparent !important;
        color: $darkgold !important;
      }
    }
  }

  .evInputField {
    .MuiInputLabel-root {
      font-family: "JioType" !important;
      @include fontSize(16px);
      transform: translate(0px, 10px) scale(1);
      &.MuiInputLabel-shrink {
        transform: translate(0px, -10px) scale(0.8);
        color: $lightgrey;
      }
    }
    .MuiInputBase-root {
      background: transparent;
      font-family: "JioType" !important;
      &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-bottom: 2px solid $darkgold;
        }
      }
      .MuiInputBase-input {
        padding-left: 0px;
        font-weight: 500;
        @include fontSize(16px);
        letter-spacing: 0px;
        font-family: "JioType" !important;
      }
      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border-bottom: 2px solid #df9f39;
        }
      }
      &:focus {
        .MuiOutlinedInput-notchedOutline {
          border-bottom: 2px solid $darkgold;
        }
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-radius: 0px;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 2px solid $lightgrey;
    }
  }
}

.regularCheckbox {
  .MuiTypography-root {
    font-size: 14px;
    color: $lightgrey;
  }
}

.popupDialog {
  .MuiPaper-root {
    border-radius: 24px;
    max-width: 100%;
  }
}

img {
  vertical-align: middle;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

a {
  text-decoration: none !important;
  cursor: pointer;
}

button {
  font-family: "JioType" !important;
}

.row {
  max-width: 1200px;
  width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-left: 12px;
  padding-right: 12px;
}

.react-multi-carousel-list,
.slick-slider {
  .react-multi-carousel-dot--active button,
  .slick-active button {
    min-width: 32px;
    opacity: 1 !important;
  }
  .slick-dots li {
    margin: 0px 4px;
    width: 12px;
    height: 12px;
    &.slick-active {
      min-width: 32px;
      width: 32px;
    }
  }
  .react-multi-carousel-dot button,
  li button {
    background: #9b6817 !important;
    border-radius: 50px !important;
    opacity: 0.3;
    border: none;
    width: 12px;
    height: 12px;
    &::before {
      display: none;
    }
  }
}

.faqTabs {
  .MuiTabs-flexContainer {
    background: #f5f5f5;
    justify-content: center;
    border-radius: 100px;
    padding: 4px;

    button {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.08px;
      text-transform: capitalize;
      color: #9b6817;
      padding: 8px 16px;
      font-family: "JioType" !important;
      border-radius: 100px;
      min-height: 40px;
      flex: 1 0 0;

      &.Mui-selected {
        background: $primary;
        color: #312109;
      }

      .MuiTouchRipple-root {
        display: none !important;
      }
    }
  }

  .MuiTabs-indicator {
    display: none;
  }

  .MuiTabPanel-root {
    padding: 32px 0px 24px;
  }
}

// myaccount custom classes CSS that can't modified in module.scss  ------
.ma-existing-policyTabs {
  .MuiTabs-flexContainer {
    border-bottom: 1px solid #e0e0e0;
    justify-content: center;

    button {
      font-size: 16px;
      font-weight: 600 !important;
      line-height: 24px;
      letter-spacing: -0.08px;
      text-transform: capitalize;
      color: #7a7a7a;
      padding-top: 13px 16px;
      padding-bottom: 14px;
      width: calc(100% / 3);

      &.Mui-selected {
        color: #001e2b;
      }

      .MuiTouchRipple-root {
        display: none !important;
      }
    }
  }

  .MuiTabs-indicator {
    height: 4px;
    background: #966d2e !important;
    border-radius: 20px;
  }

  .MuiTabPanel-root {
    padding: 24px 0px 0px 0px;
  }
}

.ratings {
  height: 60px;
  display: flex;

  .MuiRating-root {
    gap: 4px;
    align-items: center;

    label {
      .MuiRating-icon {
        svg {
          height: 40px;
          width: 40px;
        }

        &.MuiRating-iconFilled {
          color: #141414;

          svg {
            height: 60px;
            width: 60px;
          }
        }

        &.MuiRating-iconHover {
          scale: 1;
        }
      }
    }
  }
}

.MuiPickersPopper-root {
  margin-right: 320px;
}

// slider
.MuiSlider-root {
  padding: 13px 0px !important;

  .MuiSlider-rail {
    background: rgba(224, 224, 224, 1) !important;
    opacity: 1;
  }

  .MuiSlider-track {
    background: $primary;
    border: none !important;
  }

  .MuiSlider-thumb {
    background: $primary;
  }
}

@media only screen and (min-width: 768px) {
  .textCenter {
    text-align: center;
  }
}

// product page slider dots
.MuiAutocomplete-popper {
  // @include box-shadow(0px 4px 12px rgba(0, 0, 0, 0.2) !important);
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
  @include border-radius(0px 0px 8px 8px);
  z-index: 999;

  .MuiAutocomplete-paper {
    @include box-shadow(0px 4px 12px rgba(0, 0, 0, 0));
  }

  ul {
    // @include box-shadow(0px 4px 12px rgba(0,0,0,0.2));
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    max-height: 244px;
    overflow-y: auto;
    // z-index: 999;
  }
  .MuiAutocomplete-option {
    @include fontSize(13px);
    line-height: 18px;
    min-height: 32px;
  }
}

.MuiMenu-paper {
  max-height: 300px !important;
}

.MuiAutocomplete-option,
.MuiMenu-list .MuiMenuItem-root {
  font-family: "JioType";
  @include fontSize(14px);
  font-weight: 400;
}

.schedulPopup-btn {
  button {
    background: rgba(64, 29, 12, 1) !important;
    color: $white !important;

    svg {
      margin-right: 4px;
      fill: $white !important;
    }
  }
}
