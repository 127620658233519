@import "./../../../SCSS/Mixins.scss";

.mhomeWrapper {
    padding-top: 64px;
    min-height: 100vh;


    h1 {
        @include fontSize(36px);
        font-weight: 900;
        line-height: 2.250rem;
        font-style: normal;
    }

    h2 {
        @include fontSize(27px);
        font-weight: 900;
        line-height: 1.88rem;
    }

    h3 {
        @include fontSize(21px);
        font-weight: bold;
        line-height: 1.5rem;
    }

    h4 {
        @include fontSize(16px);
        font-weight: bold;
        line-height: 1.40rem;
    }

    h5 {
        @include fontSize(14px);
        font-weight: bold;
        line-height: 1.4rem;
    }

    p {
        @include fontSize(14px);
        font-weight: 400;
        line-height: 1.40rem;
    }


    .headerCarousel {
        .boxOuter {
            .cb_box {

                .cb_Boxinner {
                    @include border-radius(24px);
                    background-blend-mode: color-burn;
                    display: flex;
                    column-gap: 4px;
                    justify-content: space-between;
                    position: relative;
                    overflow: hidden;
                    z-index: 9;
                    min-height: 140px;
                    margin: 0px 12px;

                    &:before {
                        @include position(absolute, $top: 4px, $left: -12px);
                        content: "";
                        background: url("./../../../../public/images/circles.svg") no-repeat 0 0/100%;
                        width: 120px;
                        height: 120px;
                        z-index: -1;
                    }

                    &:after {
                        @include position(absolute, $top: 0px, $right: -60px);
                        content: "";
                        background: #906729;
                        transform: skew(20deg);
                        opacity: 0.05;
                        width: 180px;
                        height: 100%;
                        z-index: -1;
                    }

                    .person_box {
                        width: 116px;
                        min-width: 116px;
                        position: relative;
                        z-index: 9;

                        &::after {
                            @include position(absolute, $bottom: 12px, $left: 0px);
                            background: #EEBD7A;
                            opacity: 0.6;
                            width: 88px;
                            height: 88px;
                            content: "";
                            border-radius: 50%;
                            z-index: -1;
                        }

                        img {
                            @include position(absolute, $bottom: 0, $left: 0);
                            width: 100%;
                        }
                    }

                    .advisor_link_box {
                        width: calc(100% - 116px);
                        padding: 12px 12px 12px 0px;
                        align-self: center;

                        h6 {
                            @include fontSize(16px);
                            line-height: 20px;
                            font-weight: 900;
                            color: #654924;
                            letter-spacing: -0.48px !important;
                        }
                    }

                    .greyBtn {
                        background: $darkgold;
                        min-height: 32px;
                        color: #FFFFFF;
                        padding: 2px 12px;
                        font-size: 12px;
                        line-height: 24px;
                        font-weight: 700;
                        text-transform: capitalize;
                        border-radius: 250px;

                        &:hover {
                            background: white;
                            color: #2f2311;
                        }
                    }
                }
            }
        }

        .slick-dots {
            bottom: -30px;

            li {
                width: 8px;
                height: 8px;
                padding: 0;
                margin: 0 4px;

                button {
                    width: 8px;
                    height: 8px;
                    background: $darkgold;
                    opacity: 0.4;
                    border-radius: 200px;
                    transition: all 600ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

                    &:before {
                        display: none;
                    }
                }

                &.slick-active {
                    width: 24px;

                    button {
                        opacity: 1;
                        width: 24px;
                    }
                }
            }
        }
    }

    .formSection {
        .tabButton {
            @include border-radius(100px);
            flex-direction: column;
            gap: 4px;
            text-transform: capitalize;
            font-size: 16px;
            font-weight: 600;
            color: $darkgold;
            line-height: 24px;
            padding: 12px;
            width: 100%;
            min-height: auto;
            min-width: auto;

            svg {
                fill: $darkgold;
            }

            &.active {
                background: $primary;
                color: $warmgold;

                svg {
                    fill: $warmgold;
                }
            }
        }

        .tabsBox {
            .MuiTabs-root {
                min-height: auto;
                width: 100%;
            }

            .MuiTabs-flexContainer {
                gap: 4px;
                // justify-content: center;
            }

            .MuiTab-root {
                @include border-radius(100px);
                flex-direction: column;
                gap: 4px;
                text-transform: capitalize;
                font-size: 16px;
                font-weight: 500;
                color: $darkgold;
                line-height: 24px;
                padding: 8px 20px;
                min-width: auto;
                min-height: auto;
                // max-width: 65px;
                flex: 1;
                width: 100%;

                svg {
                    fill: $darkgold;
                }

                &.Mui-selected {
                    background: $primary;
                    color: $warmgold;

                    svg {
                        fill: $warmgold;
                    }
                }
            }

            .MuiTabScrollButton-root {
                width: 20px;
                &.Mui-disabled {
                    display: none;
                }
            }
            

            .MuiTabs-indicator {
                display: none;
            }

        }

        .MuiTabPanel-root {
            padding: 0 !important;
            text-align: left;
        }
    }

    .react-multi-carousel-track {
        margin-top: 16px;
        margin-bottom: 24px;
    }

    .mfaq_section {
        padding: 0 12px;

        .mfaqTabs {
            // .MuiTabs-flexContainer {
            //     gap: 30px;
            //     overflow: auto;

            //     button {
            //         font-size: 16px;
            //         font-weight: 600 !important;
            //         line-height: 24px;
            //         letter-spacing: -0.08px;
            //         text-transform: capitalize;
            //         color: #7A7A7A;
            //         padding: 16px 8px 14px 8px;
            //         min-width: 50px;
            //         position: relative;

            //         &.Mui-selected {
            //             color: #001E2B;
            //             position: relative;

            //             &:before {
            //                 content: "";
            //                 @include position(absolute, $bottom: 0, $left: 0);
            //                 width: 100%;
            //                 background: $primary;
            //                 height: 4px;
            //                 border-radius: 100px;
            //             }
            //         }

            //         .MuiTouchRipple-root {
            //             display: none !important;
            //         }
            //     }


            // }

            .MuiTabs-indicator {
                display: none;
            }

            .MuiTabPanel-root {
                padding: 24px 0px 0;
            }

            .MuiTabs-root {
                min-height: auto;
            }

            .MuiTabs-flexContainer {
                gap: 4px;
                // justify-content: center;
                // overflow: auto
            }

            .MuiTab-root {
                @include border-radius(100px);
                flex-direction: column;
                gap: 4px;
                text-transform: capitalize;
                font-size: 16px;
                font-weight: 500;
                color: $darkgold;
                line-height: 24px;
                padding: 8px 20px;
                min-width: auto;
                min-height: auto;
                // max-width: 65px;
                // flex: 1;

                svg {
                    fill: $darkgold;
                }

                &.Mui-selected {
                    background: $primary;
                    color: $warmgold;

                    svg {
                        fill: $warmgold;
                    }
                }
            }

            .MuiTabScrollButton-root {
                width: 20px;
                &.Mui-disabled {
                    display: none;
                }
        }
        }
    }

    // .blogCard {
    //     box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
    //     border-radius: 24px;
    //     overflow: hidden;

    //     img {
    //         width: 100%;
    //         object-fit: fill;
    //         aspect-ratio: 16/9;
    //         border-radius: 24px;
    //         box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
    //     }

    //     .cardInfo {
    //         padding: 16px;

    //         h5 {
    //             display: -webkit-box;
    //             line-clamp: 3;
    //             display: -moz-box;
    //             -webkit-line-clamp: 2;
    //             -webkit-box-orient: vertical;
    //             overflow: hidden;
    //             text-overflow: ellipsis;
    //             @include fontSize(21px);
    //         }

    //         p {
    //             display: -webkit-box;
    //             line-clamp: 3;
    //             display: -moz-box;
    //             -webkit-line-clamp: 3;
    //             -webkit-box-orient: vertical;
    //             overflow: hidden;
    //             text-overflow: ellipsis;
    //             min-height: 60px;
    //         }

    //         .contReadLink {
    //             padding: 4px 16px;
    //             color: $primary;
    //             display: inline-block;
    //             font-size: 16px;
    //             font-weight: 700;
    //         }
    //     }
    // }
    .blog_section_m {
        .react-multi-carousel-list {
            padding-bottom: 24px;
        }
    }
}

.contactlink {
    height: 56px;
    width: 56px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #CD3D00;
    box-shadow: 0 4px 16px 0 rgba(205, 61, 0, 0.4);
    border-radius: 50%;
    position: fixed;
    right: 12px;
    bottom: 20px;
    z-index: 9;
}


// Common
.why_jio_section_m {
    background: $lightMarigold20;
    padding-top: 24px;
    padding-bottom: 24px;

    ul {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        margin-top: 24px;

        &.listView {
            flex-direction: column;

            li {
                background: transparent;
                display: flex;
                gap: 8px;
                padding: 0px 0px 16px;
                border-bottom: 1px solid #E0E0E0;
                border-radius: 0px;

                &:last-child {
                    border-bottom: none;
                }
            }
        }

        li {
            flex: 1 0 0;
            background: $white;
            @include border-radius(24px);
            padding: 24px;

            img {
                height: 32px;
                width: 32px;
                margin-bottom: 6px;
            }

        }
    }
}

.testimonial_section_m {
    padding: 24px 0px;
    background: $lightMarigold20;

    .customCarouselTrack {
        padding-bottom: 24px;

        .react-multi-carousel-item {
            display: flex;
        }

        .testimonial_box {
            padding: 24px 24px 72px 24px;
            background: $white;
            margin: 0px 12px;
            @include border-radius(24px);
            position: relative;

            // box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.08), 0px 3px 14px 0px rgba(0, 0, 0, 0.08), 0px 8px 100px 0px rgba(0, 0, 0, 0.08);
            p {
                color: #7a7a7a;
                letter-spacing: -0.07;
            }

            .profile {
                position: absolute;
                bottom: 24px;

                span {
                    background: #eee;
                    height: 56px;
                    width: 56px;
                    display: inline-block;
                    border-radius: 50%;
                    overflow: hidden;
                }
            }
        }
    }
}

.partners_section_m {
    .customCarouselTrack {
        padding-bottom: 36px;

        .partner_box {
            margin: 0px 8px;
            @include border-radius(8px);
            // border: 1px solid rgba(#000000, 0.1);

            img {
                width: 100%;
            }
        }
    }
}

.career_section_m {
    padding: 24px 12px;
    background: $lightMarigold20;

    .career_openingBox {
        min-height: 324px;
        position: relative;
        z-index: 9;
        // display: flex;
        // gap: 32px;
        // align-items: center;

        img {
            flex: 1;
            border-radius: 24px;
        }
    }
}