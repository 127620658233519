@import "./../../../SCSS/Mixins.scss";

.datepicker_jio {
  position: relative;
  
  &:after {
    content: '';
    background: $white url("../../../../public/images/ic_calendar.svg") no-repeat right 12px center/24px;
    position: absolute;
    border-radius: 8px;
    right: 0px;
    bottom: 0px;
    height: 48px;
    width: 100%;
    z-index: 2;
  }

  .MuiInputBase-root {
    background: transparent;
    border-radius: 8px;
    border: 1px solid #B5B5B5;
    height: 48px;
    position: relative;
    z-index: 9;

    &:before,
    &:after {
      display: none;
    }

    &.Mui-focused {
      border-color: $primary;
    }

    .MuiInputBase-input {
      @include fontSize(18px);
      font-family: 'JioType';
      font-weight: 500;
      font-style: normal;
      padding: 4px 16px;
    }
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }

  // .MuiTextField-root {
  //   width: 100%;
  // }

  // .Mui-focused {
  //   color: $lightgrey !important;

  //   .MuiOutlinedInput-notchedOutline {
  //     border-color: $darkgold !important;
  //   }
  // }

  // .MuiInputBase-root {
  //   &:hover {
  //     border-color: $primary;
  //   }

  //   input {
  //     padding-left: 0;
  //     padding-bottom: 12px;
  //     padding-top: 6px;
  //     color: $darkgrey;
  //     font-weight: 500;
  //     font-family: "JioType";
  //     @include fontSize(18px);
  //     margin-top: 13px;
  //   }
  // }

  // label {
  //   left: -14px;
  //   font-weight: 500;
  //   font-family: "JioType";
  //   font-size: 16px;
  // }

  // .MuiInputLabel-shrink {
  //   top: 5px;
  // }

  // fieldset {
  //   border-radius: 0;
  //   border: none;
  //   border-bottom: 2px solid rgba(0, 0, 0, 0.65);
  // }

  .error {
    color: $secondary;
    @include fontSize(12px);
    text-align: right;
    @include position(absolute, $right: 0px, $bottom: -16px);
  }
}

.MuiDayPicker-weekContainer {
  .Mui-selected {
    background-color: $primary !important;
  }
}

.PrivatePickersYear-yearButton {
  &.Mui-selected {
    background-color: $primary !important;
  }
}

.MuiDialogActions-root {
  button {
    color: $primary !important;
  }
}