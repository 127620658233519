@import "./Mixins.scss";

@font-face {
    font-family: 'JioType';
    src: url('../../public/fonts/JioType-Medium.woff2') format('woff2'),
        url('../../public/fonts/JioType-Medium.woff') format('woff'),
        url('../../public/fonts/JioType-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'JioType';
    src: url('../../public/fonts/JioType-Bold.woff2') format('woff2'),
        url('../../public/fonts/JioType-Bold.woff') format('woff'),
        url('../../public/fonts/JioType-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'JioType';
    src: url('../../public/fonts/JioType-LightItalic.woff2') format('woff2'),
        url('../../public/fonts/JioType-LightItalic.woff') format('woff'),
        url('../../public/fonts/JioType-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'JioType';
    src: url('../../public/fonts/JioType-MediumItalic.woff2') format('woff2'),
        url('../../public/fonts/JioType-MediumItalic.woff') format('woff'),
        url('../../public/fonts/JioType-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'JioType';
    src: url('../../public/fonts/JioType-Light.woff2') format('woff2'),
        url('../../public/fonts/JioType-Light.woff') format('woff'),
        url('../../public/fonts/JioType-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'JioType';
    src: url('../../public/fonts/JioType-Black.woff2') format('woff2'),
        url('../../public/fonts/JioType-Black.woff') format('woff'),
        url('../../public/fonts/JioType-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}


h1 {
    @include fontSize(64px);
    font-weight: 900;
    line-height: 3.812rem;
    font-style: normal;
}
h2 {
    @include fontSize(40px);
    font-weight: 900;
    line-height: 40px;
}
h3 {
    @include fontSize(32px);
    font-weight: 900;
    font-style: normal;
    line-height: 32px;
    letter-spacing: -0.96px!important;
}
h4 {
    @include fontSize(24px);
    font-weight: bold;
    line-height: 32px;
}
h5 {
    @include fontSize(18px);
    font-weight: 500;
    line-height: 24px;
}
h6 {
    @include fontSize(16px);
    font-weight: 500;
}
.para{
    @include fontSize(16px);
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: $lightgrey;
}
p {
    @include fontSize(16px);
    font-weight: 400;
    line-height: 1.50rem;
    color: $darkgrey,
}
p {
    small {
        @include fontSize(12px);
        line-height: 16px;
        font-weight: 500;
    }
}

