@import "../../../SCSS/Mixins.scss";

.inputFields {
  position: relative;
  width: 100%;

  .MuiInputBase-root {
    background: $white;
    border-radius: 8px;
    border: 1px solid #B5B5B5;
    height: 48px!important;
    &:before, &:after {
      display: none;
    }
    &.Mui-focused {
      border-color: $primary;
    }
    .MuiInputBase-input {
      @include fontSize(18px);
      font-family: 'JioType';
      font-weight: 500;
      font-style: normal;
      padding: 4px 16px;
    }
    .MuiSelect-select {
      em {
        opacity: 0.5;
      }
    }
  }

  fieldset {
    border: none!important;
  }
  
  // Old Code
  // .MuiFormControl-root {
  //   width: 100%;
  //   &:hover {
  //     .MuiInput-root {
  //         &:before {
  //           border-bottom: 2px solid $primary;
  //         }
  //       }
  //   }
  //   .MuiFormLabel-root {
  //     @include fontSize(16px);
  //     font-family: 'JioType';
  //     font-weight: 500;
  //     font-style: normal;
  //     &.MuiInputLabel-shrink {
  //       transform: translate(0, -1.5px) scale(0.85);
  //     }
  //   }
    
  // }
    .error {
      color: $secondary;
      @include fontSize(12px);
      text-align: right;
      @include position(absolute, $right: 0px, $bottom: -16px);
    }
}

.mobileNumber {
  position: relative;
  .MuiInputBase-root {
    .MuiInputBase-input {
      padding-left: 48px!important;
    }
  }
}