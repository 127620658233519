@import "./Mixins.scss";

.mProposalWrapper {
  padding-top: 44px;
  min-height: 100vh;
  .mProposalContentSection {
    padding: 88px 12px 72px;
    min-height: 100vh;

    hr {
      border: none;
      border-top: 1px solid #e0e0e0;
    }

    .MuiAccordionSummary-root {
      min-height: 36px;

      svg {
        color: $darkgold;
        width: 30px;
        font-size: 30px;
      }

      .MuiAccordionSummary-content {
        margin-top: 0;
        margin-bottom: 0;

        .logoBox {
          display: flex;
          align-items: center;
          gap: 8px;

          img {
            width: 100px;
            height: 36px;
            border: 1px solid #b5b5b5;
            @include border-radius(8px);
          }
        }
      }
    }

    .MuiCollapse-root {
      margin-top: 12px;

      h6 {
        @include fontSize(12px);
        line-height: 20px;
        font-weight: 700;
      }

      p {
        @include fontSize(12px);
        color: $lightgrey;
      }

      .small_p {
        @include fontSize(12px);
        color: $lightgrey;
        line-height: 24px;

        span {
          float: right;
          color: $darkgrey;
          font-weight: 700;
        }
      }
    }

    .previewBox {
      border-radius: 24px;
      border: 1px solid #e0e0e0;
      padding: 12px;
      width: 100%;

      .previewBoxEdit {
        color: #966d2e;
        padding: 4px 8px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        li {
          flex: 0 0 50%;
          max-width: calc(50% - 4px);
          list-style-type: none;

          p {
            color: rgba($black, 0.6);
            @include fontSize(12px);

            span {
              @include fontSize(14px);
              line-height: 24px;
              color: $black;
              font-weight: 700;
              display: block;
              word-wrap: break-word;
            }
          }
        }
      }

      .medicalQuestionDetailBox {
        .questionP {
          line-height: 17px;
          color: $darkgrey;
          font-weight: 700;
        }

        .detailP {
          @include fontSize(12px);
          line-height: 14px;
          color: #595959;
        }

        .memberName {
          @include fontSize(14px);
          line-height: 24px;
          color: #595959;
          font-weight: 700;
        }

        .detailList {
          display: block;

          li {
            @include fontSize(12px);
            line-height: 14px;
            color: #595959;
            margin-bottom: 8px;
            max-width: 100% !important;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .mpaymentSuccessPage {
    padding: 88px 12px 24px;

    hr {
      border: none;
      border-bottom: 1px solid #b5b5b5;
    }

    ul {
      li {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        margin-bottom: 8px;
        line-height: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        span {
          float: right;
          font-weight: 700;
          color: $darkgrey;
        }
      }
    }
  }
}
