@import "./../../../SCSS/Mixins.scss";

.blogsWrapper {
    padding-top: 140px;

    @media(max-width:900px) {
        padding-top: 64px;
    }

    .mb-m-24 {
        @media(max-width:900px) {
            margin-bottom: 24px;
        }
    }
    .mb-pr-0{
        @media(max-width:600px){
            padding-right: 0 !important;
        }
    }

    .blogsheader {
        max-width: 1200px;
        margin: auto;
        padding: 0px 12px;

        @media(max-width:900px) {
            padding: 0;
        }

        .headerImg-box {
            background: url('./../../../../public/images/blog-header.jpg')no-repeat center/100%;
            border-radius: 24px;
            overflow: hidden;
            width: 100%;
            min-height: 360px;
            // padding-top: 40%;
            position: relative;
            margin-bottom: 50px;

            @media(max-width:900px) {
                border-radius: 0;
                min-height: 200px;
            }

            .boxcenter {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                min-width: 312px;

                h1 {
                    color: $white;
                    margin-bottom: 32px;
                    text-align: center;
                }

                .filterDropdown {
                    label {
                        display: none;
                    }

                    fieldset {
                        border: none;
                        border-color: none;
                        border-radius: 40px;
                        background: $white;
                        z-index: -9;
                    }

                    .MuiInputBase-root {
                        border: none;
                        border-color: transparent;

                        .MuiSelect-select {
                            padding: 12px;
                            text-align: center;
                            font-weight: 700;
                            color: $primary;
                        }
                    }
                }
            }
        }
    }

    .blogCard-sec {
        margin-bottom: 64px;

        @media(max-width:600px) {
            padding: 0px 12px;
            margin-bottom: 40px;
        }

        .blog-card {
            box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
            border-radius: 24px;
            overflow: hidden;
            margin-bottom: 24px;

            img {
                width: 100%;
                object-fit: fill;
                border-radius: 24px;
                &:hover {
                    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
                }
            }

            .card-info {
                padding: 16px;

                h5 {
                    display: -webkit-box;
                    line-clamp: 3;
                    display: -moz-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-weight: bold;
                }

                p {
                    display: -webkit-box;
                    line-clamp: 3;
                    display: -moz-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    min-height: 60px;
                }

                .cont-read-link {
                    padding: 4px 16px;
                    color: $primary;
                    display: inline-block;
                    font-size: 16px;
                    font-weight: 700;
                }
            }

        }

        .MuiStack-root {
            padding: 5px 0px;

            .MuiPagination-root {
                ul {
                    justify-content: flex-end;

                    // @media(max-width:600px) {
                    //     justify-content: space-between;
                    // }

                    li {
                        button {
                            font-weight: 700;
                            color: rgba(255, 227, 174, 1);
                            font-size: 16px;

                            &.Mui-selected {
                                color: rgba(254, 247, 233, 1);
                                background: $darkgold;
                            }

                            &.MuiPaginationItem-previousNext {
                                color: $black;

                                svg {
                                    font-size: 25px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .blogsdetail-sec {
        margin-bottom: 64px;

        @media(max-width:900px) {
            padding: 32px 12px 0px;
            margin-bottom: 40px;
        }

        h2 {
            margin-bottom: 24px;

            @media(max-width:600px) {
                font-size: 28px;
                line-height: normal;
                margin-bottom: 16px;
            }
        }

        .tag {
            padding: 4px 8px;
            border-radius: 4px;
            display: inline-block;
            color: $primary;
            background: $lightMarigold20;
        }

        .img-overlay {
            border-radius: 24px;
            overflow: hidden;
            position: relative;
            

            .img-bg {
                overflow: hidden;
                border-radius: 24px;
                border: 1px solid rgba(0, 0, 0, 0.08);
                img {
                    width: 100%;
                    object-fit: fill;
                    // aspect-ratio: 16/9;
                    // min-height: 232px;
                    
                }
            }
            .overlay-sec{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 9;
                padding: 15px 20px;
                .logo{
                    height: 16px;
                }
                h4{
                    color: $white;
                    position: absolute;
                    bottom: 15px;
                    margin-right: 20%;
                    @media(max-width:600px){
                        font-size: 17px;
                        line-height: normal;
                    }
                }
            }
        }

        h6{
            font-weight: 600;
        }

        .share-sec{
            display: flex;
            gap:20px;
            align-items: center;
            ul{
                padding-left: 0;
                display: flex;
                gap: 12px;
                li{
                    list-style: none;
                    .sharebtn{
                        background: $darkgold;
                        border-radius: 50%;
                        width: 32px;
                        height: 32px;
                        display: inline-block;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img{
                            height: 20px;
                            width: 20px;
                        }
                    }
                }
            }
        }
        ul{
            padding-left: 20px;
            li{
                list-style-type: disc;
                span{
                    font-weight: 700;
                }
                font-size: 14px;
                line-height: 21px;
                margin-bottom: 5px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        .tabledata{
            width: 100%;
            border-collapse: collapse;
            border: 1px solid #f2f2f2;
            th,td{
                text-align: center;
                width: 50%;
                padding: 4px;
                border-bottom: 1px solid #f2f2f2;
                border-right: 1px solid #f2f2f2;
                font-size: 14px;
                line-height: 21px;
                &:last-child{
                    border-right: 0;
                }
            }
            th{
                 background-color: #f2f2f2;;
            }

            &.typeTable{
                th,td{
                    width: 25%;
                }
            }
            &.planExplanation{
                th,td{
                    width: 40%;
                    text-align: left;
                    &:first-child{
                        width: 20%;
                    }
                }
            }
        }
    }
}