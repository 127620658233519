@import "../../../SCSS/Mixins.scss";

%button {
  // width: 100%;

  .MuiButton-root {
    box-shadow: none;
    border-radius: 100px;
    font-weight: bold;
    font-family: "JioType";
    text-transform: inherit;
    padding: 10px 20px;
    @include fontSize(16px);
    line-height: 24px;
    border: none;
    cursor: pointer;
    letter-spacing: -0.08px;

    &:hover {
      border: none;
    }

    // img {
    //     height: 24px;
    //     animation: rotate infinite 1s linear;
    // }
    // svg {
    //     height: 24px;
    //     width: 24px;
    // }
    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }
  }

  // &.loading {
  //     .MuiButton-root {
  //         background-color: rgba($primary, 0.3);
  //         padding: 4px 12px !important;
  //     }
  // }

  &.small {
    .MuiButton-root {
      padding: 4px 12px;
      @include fontSize(12px);

      svg {
        height: 16px;
        width: 16px;
      }
    }

    &.loading {
      .MuiButton-root {
        background-color: rgba($primary, 0.3);
        padding: 0px 12px;
      }
    }
  }

  &.large {
    .MuiButton-root {
      padding: 16px 24px;
      @include fontSize(16px);
    }

    &.loading {
      .MuiButton-root {
        background-color: rgba($primary, 0.3);
        padding: 12px 24px;
      }
    }
  }

  &.extraLarge {
    .MuiButton-root {
      padding: 16px 32px;
      @include fontSize(24px);
      line-height: 32px;

      img {
        height: 48px;
      }
    }

    &.loading {
      .MuiButton-root {
        background-color: rgba($primary, 0.3);
        padding: 14px 32px;
      }
    }
  }

  &.disabled {
    .MuiButton-root {
      opacity: 0.3;
    }
  }
}

.primaryBtns {
  @extend %button;

  .MuiButton-root {
    background-color: $primary;
    color: $warmgold;

    svg {
      fill: $warmgold;
      .progress {
        stroke: $darkgold !important;
      }
    }

    &:hover {
      box-shadow: none;
      background-color: #fce0bd;
      color: #644920;

      svg {
        fill: #644920;
      }
    }

    &:focus-visible {
      box-shadow: none;
      background-color: $primary;
      outline: 4px solid $darkgold;
    }

    &:active {
      box-shadow: none;
      background-color: $darkgold;
    }
  }
}

.secondaryBtns {
  @extend %button;

  .MuiButton-root {
    background-color: transparent;
    color: #9b6817;
    outline: 1px solid #b5b5b5;
    outline-offset: -1px;

    svg {
      fill: $darkgold;
    }

    &:hover {
      background-color: #fcf5ed;
      box-shadow: none;
      border: none;
      color: $darkgold;

      svg {
        fill: $darkgold;
      }
    }

    &:focus-visi {
      box-shadow: none;
      border: none;
      background-color: transparent;
      outline: 4px solid $darkgold;
      color: $darkgold;
    }

    &:active {
      box-shadow: none;
      border: none;
      outline: 1px solid #b5b5b5;
      background-color: #fce0bd;
      color: #644920;
    }
  }
}

.tertiaryBtns {
  @extend %button;

  .MuiButton-root {
    color: #9b6817;
    border-radius: 12px;
    padding-left: 0px !important;
    padding-right: 0px !important;

    &:hover {
      background-color: transparent;
      box-shadow: none;
      color: $primary;
    }

    // &:focus {
    //     box-shadow: none;
    //     background-color: transparent;
    //     outline: 4px solid $darkgold;
    //     color: $darkgold;
    // }

    &:active {
      box-shadow: none;
      outline: none;
      background-color: transparent;
      color: #644920;
    }
  }
}
