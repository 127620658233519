@import "./../../../../SCSS/Mixins.scss";

.loginBtns .userIcon {
    background: $lightMarigold20;
    @include border-radius(40px);
    padding: 5px 6px;
    height: 32px;
    width: 32px;
    min-width: 32px;
    min-height: 32px;
    &:hover {
        background: $lightMarigold30;
    }

    img {
        width: 100%;
    }
}
// sidemenu css
.MuiDrawer-root {
    .MuiPaper-root {
        min-width: 60%;
        max-width: 60%;

        .menuWrapper {
            position: relative;
            min-height: 100vh;
            padding-top: 80px;

            .navSec {
                padding: 12px 20px 12px 20px;
                min-height: 64px;
                @include position (fixed, $top: 0, $left: 0);
                width: 60%;
                box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
                background: $white;
                z-index: 9;
            }

            .menu-section {
                padding: 0px 20px 20px 20px;

                ul {
                    li {
                        padding-top: 8px;
                        padding-bottom: 8px;
                        border-bottom: 1px solid $lightbg;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .nav-link {
                            text-decoration: none;
                            color: #000;
                            @include fontSize(16px);
                            font-weight: 500;
                            cursor: pointer;
                            padding: 8px 0px;
                            display: inline-flex;
                            align-items: center;
                            width: 100%;

                            svg {
                                margin-left: 8px;
                            }

                            &:hover,
                            :active {
                                color: $primary;
                            }
                        }

                        .MuiCollapse-root {
                            background: $lightMarigold20;
                            margin: auto;
                            @include border-radius(10px);

                            .MuiCollapse-wrapper {
                                padding: 8px 0px;
                            }
                            a {
                                padding: 8px 8px 8px 12px;
                                color: $black;
                                display: block;
                                @include fontSize(14px);
                                margin: 4px 0px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.personMenu{
    .MuiPaper-elevation {
        top: 45px !important;
        border-radius: 12px !important;
        min-width: 150px !important;

        .MuiList-root {
            .MuiMenuItem-root {
                @include fontSize(14px);
                font-family: 'jioType';
                line-height: normal;
                min-height: 40px;
                border-bottom: 1px solid #d9d9d9;
                font-weight: 600;
                color: $warmgold;

                &:hover,
                :active {
                    color: $primary;
                }
            }
        }
    }
}
