@import "./Mixins.scss";

.form_steps {
  .MuiStepper-root {
    .MuiStepLabel-iconContainer {
      outline: 1px solid #b5b5b5;
      border-radius: 40px;
      font-family: "JioType";
      font-size: 16px;
      font-weight: bold;
      color: #b5b5b5;

      svg {
        height: 32px;
        width: 32px;
        fill: transparent;

        text {
          fill: #b5b5b5;
          font-family: "JioType";
        }
      }

      &.Mui-active {
        outline: none;

        svg {
          fill: $lightMarigold30;

          text {
            fill: $warmgold;
          }
        }
      }

      &.Mui-completed {
        outline: 1px solid $lightMarigold30;
        background-color: $lightMarigold20;
        position: relative;

        &:before {
          content: "";
          @include position(absolute, $left: 10px, $top: 11px);
          border-left: 1.5px solid $darkgrey;
          border-bottom: 1.5px solid $darkgrey;
          transform: rotate(-45deg);
          height: 5px;
          width: 10px;
        }

        svg {
          fill: $lightMarigold20;
        }
      }
    }

    .MuiStepLabel-label {
      font-family: "JioType";
      font-size: 16px;
      font-weight: 900;
      line-height: 20px;
      margin-top: 12px;
    }

    .MuiStepConnector-root {
      top: 16px;
      left: calc(-50% + 24px);
      right: calc(50% + 24px);

      .MuiStepConnector-line {
        border-color: #e0e0e0;
      }
    }
  }
}

.mfgLogo {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;

  li {
    border: 1px solid #b5b5b5;
    border-radius: 50px;
    font-size: 16px;
    font-weight: bold;
    padding: 8px 16px 8px 48px;
    color: $lightgrey;
    cursor: pointer;

    &:hover,
    &.active {
      background: #e8e8fc;
      border-color: #000093;
    }

    // Car MFG
    $othericons: maruti, hyundai, tata, toyota, kia, honda, mg, mahindra,
      volkswagen, skoda, bmw, mercedes, audi, renault, ford;
    $k: 0;

    @each $icons in $othericons {
      $bacRightP: $k * 30;
      $k: $k + 1;

      &.#{$icons} {
        position: relative;

        &:before {
          background: url("../../public/images/car-makers.svg") no-repeat -#{$bacRightP}px 0px/480px;
          content: "";
          @include position(absolute, $left: 12px, $top: 3px);
          height: 30px;
          width: 30px;
        }
      }
    }

    // Bike MFG
    $othericons: suzuki, royalenfield, bajaj, hondaa, ktm, yamaha, tvs, hero,
      harley;
    $k: 0;

    @each $icons in $othericons {
      $bacRightP: $k * 30;
      $k: $k + 1;

      &.#{$icons} {
        position: relative;

        &:before {
          background: url("../../public/images/bike-makers.svg") no-repeat -#{$bacRightP}px 0px/330px;
          content: "";
          @include position(absolute, $left: 12px, $top: 3px);
          height: 30px;
          width: 30px;
        }
      }
    }
  }
}

.rtoCity {
  li {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 16px;

    .city {
      border: 1px solid #b5b5b5;
      border-radius: 50px;
      font-size: 16px;
      font-weight: bold;
      padding: 8px 16px;
      color: $lightgrey;
      cursor: pointer;
      text-align: center;
      width: calc(25% - 12px);

      &:hover,
      &.active {
        background: $lightMarigold20;
      }
    }

    .rtocode {
      border: 1px solid #b5b5b5;
      border-radius: 50px;
      font-size: 16px;
      font-weight: bold;
      padding: 8px 16px;
      color: $lightgrey;
      cursor: pointer;
      text-align: center;

      &:hover,
      &.active {
        background: $lightMarigold20;
      }
    }
  }
}

// mobile steps
.mform_steps {
  .MuiStepper-root {
    .MuiStep-root {
      padding: 0;
    }

    .MuiStepLabel-iconContainer {
      border: 1px solid #b5b5b5;
      // @include border-radius(40px!important);
      -webkit-border-radius: 40px;
      -ms-border-radius: 40px;
      border-radius: 40px;
      font-family: "JioType";
      font-size: 16px;
      font-weight: bold;
      color: #b5b5b5;

      svg {
        height: 32px;
        width: 32px;
        fill: transparent;

        text {
          fill: #b5b5b5;
          font-family: "JioType";
        }
      }

      &.Mui-active {
        outline: none;

        svg {
          fill: $lightMarigold30;
          height: 32px;
          width: 32px;

          text {
            fill: $warmgold;
          }
        }

      }

      &.Mui-completed {
        outline: 1px solid $lightMarigold30;
        background-color: $lightMarigold20;
        position: relative;

        &:before {
          content: "";
          @include position(absolute, $left: 11px, $top: 12px);
          border-left: 1.5px solid $darkgrey;
          border-bottom: 1.5px solid $darkgrey;
          transform: rotate(-45deg);
          height: 4px;
          width: 8px;
        }

        svg {
          fill: $lightMarigold20;
        }
      }
    }

    .MuiStepLabel-label {
      font-family: "JioType";
      font-size: 13px;
      font-weight: 700;
      line-height: 18px;
      margin-top: 12px;
      display: none;
    }

    .MuiStepConnector-root {
      top: 16px;
      left: calc(-50% + 24px);
      right: calc(50% + 24px);

      .MuiStepConnector-line {
        border-color: #e0e0e0;
      }

      &.Mui-active {
        .MuiStepConnector-line {
          border-color: $lightMarigold30;
        }
      }
    }
  }
}

.m_rtoCity {
  li {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;

    .city {
      border: 1px solid #b5b5b5;
      border-radius: 50px;
      font-size: 16px;
      font-weight: bold;
      padding: 8px 16px;
      color: $lightgrey;
      cursor: pointer;
      text-align: center;
      width: calc(50% - 8px);

      &:hover,
      &.active {
        background: $lightMarigold20;
      }

      &.disableSate {
        opacity: 0.5;
      }
    }

    .rtocode {
      border: 1px solid #b5b5b5;
      border-radius: 50px;
      font-size: 16px;
      font-weight: bold;
      padding: 8px;
      color: $lightgrey;
      cursor: pointer;
      text-align: center;
      width: calc(25% - 12px);

      &:hover,
      &.active {
        background: $lightMarigold20;
      }
    }
  }
}

.m_mfgLogo {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;

  li {
    border: 1px solid #b5b5b5;
    border-radius: 50px;
    font-size: 16px;
    font-weight: bold;
    padding: 4px 16px;
    color: $lightgrey;
    cursor: pointer;
    width: calc(50% - 8px);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    &:hover {
      border: 1px solid $lightMarigold30;
      background-color: $lightMarigold20;
    }

    span {
      width: 30px;
      height: 30px;
      display: inline-block;

      // Car MFG
      $othericons: maruti, hyundai, tata, toyota, kia, honda, mg, mahindra,
        volkswagen, skoda, bmw, mercedes, audi, renault, ford;
      $k: 0;

      @each $icons in $othericons {
        $bacRightP: $k * 30;
        $k: $k + 1;

        &.#{$icons} {
          background: url("../../public/images/car-makers.svg") no-repeat -#{$bacRightP}px 0px/480px;
          width: 30px;
          height: 30px;
        }
      }

      // Bike MFG
      $othericons: suzuki, royalenfield, bajaj, hondaa, ktm, yamaha, tvs, hero,
        harley;
      $k: 0;

      @each $icons in $othericons {
        $bacRightP: $k * 30;
        $k: $k + 1;

        &.#{$icons} {
          position: relative;

          &:before {
            background: url("../../public/images/bike-makers.svg") no-repeat -#{$bacRightP}px 0px/330px;
            content: "";
            @include position(absolute, $left: 12px, $top: 3px);
            height: 30px;
            width: 30px;
          }
        }
      }
    }

    // // Car MFG
    // $othericons: maruti, hyundai, tata, toyota, kia, honda, mg, mahindra, volkswagen, skoda, bmw, mercedes, audi, renault, ford;
    // $k: 0;

    // @each $icons in $othericons {
    //     $bacRightP: $k * 30;
    //     $k: $k + 1;

    //     &.#{$icons} {
    //         position: relative;

    //         &:before {
    //             background: url("../../public/images/car-makers.svg") no-repeat -#{$bacRightP}px 0px/480px;
    //             content: '';
    //             @include position(absolute, $left: 12px, $top: 3px);
    //             height: 30px;
    //             width: 30px;
    //         }
    //     }
    // }

    // // Bike MFG
    // $othericons: suzuki, royalenfield, bajaj, hondaa, ktm, yamaha, tvs, hero, harley;
    // $k: 0;

    // @each $icons in $othericons {
    //     $bacRightP: $k * 30;
    //     $k: $k + 1;

    //     &.#{$icons} {
    //         position: relative;

    //         &:before {
    //             background: url("../../public/images/bike-makers.svg") no-repeat -#{$bacRightP}px 0px/330px;
    //             content: '';
    //             @include position(absolute, $left: 12px, $top: 3px);
    //             height: 30px;
    //             width: 30px;
    //         }
    //     }
    // }
  }
}

.m_box,
.mfuel_box {
  @extend .m_mfgLogo;

  li {
    line-height: 24px;
    padding: 7px 16px;
  }
}

.mfuel_box {
  li {
    width: 100%;
    justify-content: flex-start;
  }
}

.advantage {
  color: $lightMarigold40;
  text-transform: uppercase;
  font-weight: 700;
  @include fontSize(27px);
  line-height: 32px;
  @media only screen and (max-width: 767px) {
    @include fontSize(16px);
  }
}

.mtestimonial_box {
  padding: 16px 16px 56px 16px;
  background: $white;
  // margin: 0px 12px;
  // @include border-radius(24px);
  position: relative;

  // box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.08), 0px 3px 14px 0px rgba(0, 0, 0, 0.08), 0px 8px 100px 0px rgba(0, 0, 0, 0.08);
  p {
    color: #7A7A7A;
    letter-spacing: -0.07;
  }

  .profile {
    position: absolute;
    left: 16px;
    bottom: 16px;

    span {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      overflow: hidden;

      img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
      }
    }
  }
}