@import "./../../../SCSS/Mixins.scss";

.homeWrapper {
  padding-top: 162px;

  .header {
    .boxOuter {
      .mybox {
        .myBoxinner {
          @include border-radius(86px);
          // background: $primary;
          // background-blend-mode: color-burn;
          display: flex;
          column-gap: 52px;
          justify-content: space-between;
          position: relative;
          overflow: hidden;
          z-index: 9;
          min-height: 410px;
          margin: 0px 12px;

          &:before {
            @include position(absolute, $top: 4px, $left: -12px);
            content: "";
            background: url("./../../../../public/images/circles.svg") no-repeat
              0 0/100%;
            width: 360px;
            height: 360px;
            z-index: -1;
          }

          &:after {
            @include position(absolute, $top: 0px, $right: -60px);
            content: "";
            background: #906729;
            transform: skew(20deg);
            opacity: 0.05;
            width: 380px;
            height: 100%;
            z-index: -1;
          }

          .person_box {
            width: calc(35% - 26px);
            position: relative;
            z-index: 9;

            &::after {
              @include position(absolute, $bottom: -60px, $left: 0px);
              background: #eebd7a;
              opacity: 0.6;
              width: 320px;
              height: 320px;
              content: "";
              border-radius: 50%;
              z-index: -1;
            }

            img {
              @include position(absolute, $bottom: 0%, $left: 0);
              max-width: 440px;
            }
          }

          .advisor_link_box {
            width: calc(64% - 26px);
            padding: 24px 0px;
            align-self: center;

            h3 {
              @include fontSize(24px);
              font-weight: 700;
              line-height: 40px;
              letter-spacing: 0.12px;
            }

            p {
              @include fontSize(51px);
              font-weight: 900;
              line-height: 64px;
              color: #654924;
              margin-bottom: 42px;
              letter-spacing: -1.53px;
            }

            .blackBtn {
              background: #2f2311;
              min-height: 64px;
              color: #ffffff;
              padding: 16px 32px;
              font-size: 24px;
              font-weight: 700;
              text-transform: capitalize;
              border-radius: 250px;

              &:hover {
                background: white;
                color: #2f2311;
              }
            }
          }
        }
      }
    }
  }

  .form_section {
    position: relative;
    padding: 42px 0px;
    margin-bottom: 40px;

    // &:after {
    //   @include position(absolute, $top: 0, $left: 0);
    //   content: "";
    //   background: #fef7e9;
    //   width: 100%;
    //   height: 100%;
    //   z-index: -1;
    // }

    .form_box {
      max-width: 648px;
      width: 100%;
      border-radius: 32px;
      background: $lightMarigold20;
      // box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.24);
      // min-height: 584px;
      padding: 32px;

      .tabButton {
        @include border-radius(100px);
        // flex-direction: column;
        gap: 4px;
        text-transform: capitalize;
        font-size: 16px;
        font-weight: 500;
        color: $darkgold;
        line-height: 24px;
        padding: 8px 24px;
        width: 100%;

        svg {
          fill: $darkgold;
        }

        &.active {
          background: $primary;
          color: $warmgold;

          &:hover {
            background: #fce0bd;
          }

          svg {
            fill: $warmgold;
          }
        }
      }
    }
  }

  .contact_advisor {
    .contact_advisor_box {
      @include border-radius(92px);
      // background: linear-gradient(109deg, #D9D9D9 7.16%, rgba(217, 217, 217, 0.00) 87.35%);
      // background-blend-mode: color-burn;
      background: #eebd7a;
      // background-blend-mode: color-burn;
      display: flex;
      column-gap: 52px;
      justify-content: space-between;
      position: relative;
      overflow: hidden;
      z-index: 9;

      &:before {
        @include position(absolute, $top: 4px, $left: -12px);
        content: "";
        background: url("./../../../../public/images/white-cricles.svg")
          no-repeat 0 0/100%;
        width: 360px;
        height: 360px;
        z-index: -1;
      }

      .person_box {
        width: 400px;
        position: relative;
        z-index: 9;
        left: 26px;
        bottom: -26px;
      }

      .advisor_link_box {
        width: calc(64% - 26px);
        padding: 24px 0px;
        align-self: center;

        h6 {
          color: #654924;
          @include fontSize(32px);
          font-weight: 900;
          margin-bottom: 16px;
        }

        h2 {
          @include fontSize(64px);
          line-height: 64px;
          letter-spacing: -1.92px !important;
          font-weight: 900;
          color: #906729;
          margin-bottom: 25px;
        }
      }
    }
  }
}

.desktopBannerSlider {
  .slick-slider {
    .slick-track {
      z-index: 9;

      &:before {
        background: linear-gradient(
          270deg,
          rgba(217, 217, 217, 0) 4.58%,
          rgba(0, 0, 0, 0.4) 50.59%
        );
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: calc(100% - 16px);
        width: 80%;
        z-index: 1;
      }
    }
  }
}

.heroBanner {
  position: relative;
  width: 100%;

  &:after {
    background: linear-gradient(
      270deg,
      rgba(217, 217, 217, 0) 4.58%,
      rgba(0, 0, 0, 0.4) 50.59%
    );
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 80%;
    z-index: 1;

    @media only screen and (max-width: 899px) {
      background: linear-gradient(
        180deg,
        rgba(217, 217, 217, 0) 40%,
        rgba(0, 0, 0, 0.4) 70%
      );
      min-height: 430px;
      height: 430px;
      width: 100%;
    }
  }

  .bgimg {
    min-height: 650px;
    max-height: 62vh;
    height: 62vh;
    object-fit: cover;
    object-position: center top;
    z-index: 0;

    @media only screen and (max-width: 899px) {
      min-height: 430px;
      height: 430px;
      position: absolute;
    }
  }

  .innerWrapper {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 9;
    padding-left: 12px;
    padding-right: 12px;

    @media only screen and (max-width: 899px) {
      position: relative;
      padding: 12px;
      height: auto;
    }

    .contentBox {
      @media only screen and (max-width: 899px) {
        height: 440px;
        display: flex;
        justify-content: end;
        padding-bottom: 40px;
      }
    }

    .formBox {
      background: $lightMarigold20;
      padding: 32px 20px;
      border-radius: 32px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .primaryBtns {
        margin-left: 6px;
      }

      .secondaryBtns {
        margin-right: 6px;
      }

      @media only screen and (max-width: 899px) {
        border-radius: 24px;
        padding: 16px;
        gap: 16px;

        .primaryBtns {
          margin-left: 0px;
        }

        .secondaryBtns {
          margin-right: 0px;
        }
      }

      h3 {
        @media only screen and (max-width: 899px) {
          @include fontSize(16px);
          line-height: 20px;
        }
      }
      h4 {
        @media only screen and (max-width: 899px) {
          @include fontSize(18px);
          font-weight: bold;
          line-height: 20px;
        }
      }
    }
  }

  h2 {
    @include fontSize(64px);
    line-height: 64px;
    text-align: left;

    @media only screen and (max-width: 899px) {
      @include fontSize(24px);
      line-height: 28px;
    }
  }

  h1 {
    @include fontSize(18px);
    font-weight: 500;
    line-height: 24px;

    @media only screen and (max-width: 899px) {
      @include fontSize(16px);
      line-height: 24px;
    }
  }

  p {
    color: $white;
    @include fontSize(18px);
    display: flex;
    gap: 16px;
    align-items: center;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0px;
    }

    img {
      width: 32px;

      @media only screen and (max-width: 899px) {
        width: 20px;
      }
    }

    @media only screen and (max-width: 899px) {
      @include fontSize(16px);
      line-height: 24px;
      margin-bottom: 4px;
    }
  }
}

// Common
.lightGoldBg {
  background: $lightMarigold20;
  padding: 64px 0px;

  @media (max-width: 900px) {
    padding: 24px 0px;
  }
}

.why_jio_section {
  @media (max-width: 900px) {
    padding: 0px 12px;
  }

  h2 {
    text-align: center;

    @media (max-width: 900px) {
      @include fontSize(24px);
      line-height: 28px;
      text-align: left;
    }
  }

  h4 {
    text-align: center;
    margin: 0px auto 24px;

    @media (max-width: 900px) {
      @include fontSize(16px);
      line-height: 24px;
      text-align: left;
      margin: 0px auto 12px;
    }
  }

  h5 {
    text-align: center;
    margin: 24px auto;

    @media (max-width: 900px) {
      @include fontSize(16px);
      line-height: 24px;
      text-align: left;
      margin: 16px auto 24px;
    }
  }

  ul {
    display: flex;
    // align-items: flex-start;
    align-content: flex-start;
    gap: 16px;
    align-self: stretch;
    flex-wrap: wrap;
    margin-top: 24px;

    @media (max-width: 899px) {
      gap: 24px;
    }

    &.listView {
      flex-direction: column;

      li {
        background: transparent;
        display: flex;
        gap: 12px;
        padding: 0px 0px 16px;
        border-bottom: 1px solid #e0e0e0;
        border-radius: 0px;
        flex-direction: row;
        width: 100%;

        &:last-child {
          border-bottom: none;
        }

        h5 {
          text-align: left;
          margin-top: 0px;
        }

        p {
          @media (max-width: 1024px) {
            @include fontSize(14px);
            line-height: 20px;
          }
        }
      }
    }

    li {
      flex: 1 0 0;
      flex-direction: column;
      align-items: flex-start;
      background: $white;
      @include border-radius(24px);
      padding: 24px;
      min-width: 300px;

      @media (max-width: 899px) {
        width: calc(50% - 16px);
      }

      // p {
      //   display: -webkit-box;
      //     line-clamp: 3;
      //     display: -moz-box;
      //     -webkit-line-clamp: 3;
      //     -webkit-box-orient: vertical;
      //     overflow: hidden;
      //     text-overflow: ellipsis;
      // }
      h6 {
        @include fontSize(24px);

        @media (max-width: 900px) {
          @include fontSize(16px);
          line-height: 24px;
        }
      }

      img {
        height: 40px;
        width: 40px;
        margin-bottom: 6px;

        @media (max-width: 900px) {
          height: 32px;
          width: 32px;
        }
      }
    }
  }
}

.blogs_section {
  @media (max-width: 900px) {
    padding: 0px 12px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 24px 0px;
  }

  h2 {
    text-align: center;

    @media (max-width: 900px) {
      @include fontSize(24px);
      line-height: 28px;
      text-align: left;
    }
  }

  h4 {
    text-align: center;
    margin: 24px auto;

    @media (max-width: 900px) {
      @include fontSize(16px);
      line-height: 24px;
      text-align: left;
      margin: 0px auto 12px;
    }
  }

  h5 {
    text-align: center;
    margin: 24px auto;

    @media (max-width: 900px) {
      @include fontSize(16px);
      line-height: 24px;
      text-align: left;
      margin: 16px auto 0px;
    }
  }
}

.blogCard {
  border-radius: 16px;
  overflow: hidden;
  width: calc((100% / 3) - 11px);

  @media (max-width: 900px) {
    width: 100%;
  }

  img {
    width: 100%;
    object-fit: fill;
  }

  .cardInfo {
    padding: 28px;
    background: $lightMarigold20;
    border-radius: 16px;
    position: relative;
    margin-top: -16px;

    @media (max-width: 900px) {
      padding: 24px;
      border-radius: 0px;
    }

    h5 {
      display: -webkit-box;
      line-clamp: 3;
      display: -moz-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      @include fontSize(24px);
      line-height: 28px;
      font-weight: 900;
      text-align: left;
      margin-top: 0px;

      @media (max-width: 900px) {
        @include fontSize(16px);
        line-height: 20px;
        text-align: left;

        @media (max-width: 900px) {
          color: #401d0c;
        }
      }
    }

    p {
      display: -webkit-box;
      line-clamp: 2;
      display: -moz-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      color: rgba(#000000, 0.65);
      line-height: 20px;
      letter-spacing: -0.48px;

      @media (max-width: 900px) {
        @include fontSize(16px);
        color: rgba(#401d0c, 0.78);
      }
    }

    .contReadLink {
      padding: 4px 16px;
      color: #9b6817;
      display: inline-block;
      font-size: 16px;
      font-weight: 700;
    }
  }
}

.testimonial_section {
  padding: 64px 12px;
  background: $lightMarigold20;

  @media (max-width: 900px) {
    padding: 24px 12px;
  }

  h2 {
    text-align: center;
    margin-bottom: 64px !important;

    @media (max-width: 900px) {
      @include fontSize(24px);
      line-height: 28px;
      text-align: left;
      margin-bottom: 24px !important;
    }
  }

  h5 {
    text-align: center;
    margin-bottom: 64px;

    @media (max-width: 900px) {
      @include fontSize(16px);
      line-height: 24px;
      text-align: left;
      margin-bottom: 24px;
    }
  }

  .customCarouselTrack {
    padding-bottom: 64px;

    .react-multi-carousel-item {
      display: flex;
    }

    .testimonial_box {
      padding: 24px 24px 72px 24px;
      background: $white;
      margin: 0px 12px;
      @include border-radius(24px);
      position: relative;
      text-align: left;

      img {
        height: 40px;

        @media (max-width: 900px) {
          height: 32px;
        }
      }

      h4 {
        @media (max-width: 900px) {
          @include fontSize(16px);
          line-height: 24px;
        }
      }

      // box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.08), 0px 3px 14px 0px rgba(0, 0, 0, 0.08), 0px 8px 100px 0px rgba(0, 0, 0, 0.08);
      p {
        color: #7a7a7a;
        letter-spacing: -0.07;
      }

      .profile {
        position: absolute;
        bottom: 24px;

        h5 {
          margin-bottom: 4px;
        }

        span {
          background: #eee;
          height: 56px;
          width: 56px;
          display: inline-block;
          border-radius: 50%;
          overflow: hidden;
        }
      }
    }
  }
}

.partners_section {
  .customCarouselTrack {
    padding-bottom: 64px;

    .partner_box {
      margin: 0px 12px;
      @include border-radius(8px);
      // border: 1px solid rgba(#000000, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      img {
        width: 100%;
        max-width: 250px;
      }
    }
  }
}

.addonSection {
  .customCarouselTrack {
    padding-bottom: 64px;

    .addon_box {
      margin: 0px 8px;

      @media (max-width: 900px) {
        margin: 0px;
      }

      .addonCard {
        border-radius: 16px;
        overflow: hidden;
        cursor: pointer;

        img {
          width: 100%;
          // object-fit: fill;
        }

        .cardInfo {
          padding: 28px;
          background: $lightMarigold20;
          border-radius: 16px;
          position: relative;
          margin-top: -16px;

          h5 {
            @include fontSize(24px);
            line-height: 28px;
            font-weight: 900;
            min-height: 56px;
            max-height: 56px;
            // white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-clamp: 3;
            display: -moz-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            @media (max-width: 899px) {
              min-height: 30px;
            }
          }

          p {
            color: rgba(#000000, 0.65);
            display: -webkit-box;
            line-clamp: 2;
            display: -moz-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .contReadLink {
            padding: 4px 16px;
            color: #9b6817;
            display: inline-block;
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
    }
  }
}

.career_section {
  padding: 64px 0px;
  background: $lightMarigold20;

  .career_openingBox {
    min-height: 324px;
    position: relative;
    z-index: 9;
    display: flex;
    gap: 32px;
    align-items: center;

    img {
      flex: 1;
      border-radius: 24px;
    }
  }
}

.cardBox {
  background: $white;
  border-radius: 24px;
  padding: 24px;

  h3 {
    margin-top: 12px;
    margin-bottom: 20px;

    // min-height: 66px;
    @media (max-width: 900px) {
      @include fontSize(16px);
      line-height: 20px;
      margin-bottom: 16px;
      min-height: auto;
    }
  }
}
