@import "./../../../../SCSS/Mixins.scss";

.mfooter {
    background: rgba(245, 245, 245, 1);
    position: relative;

    .topPart {

        h6 {
            font-weight: bold;
            color: #654924;
            margin-bottom: 16px;
        }

        p {
            color: #654924;
            font-weight: 300;
        }

        ul {
            li {
                margin-bottom: 8px;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    color: #595959;
                    font-size: 14px;
                    line-height: 20px;
                    padding: 2px 0px;
                    display: inline-block;
                    font-weight: 500;

                    &:hover {
                        color: $primary;
                    }
                    &.socialMediaIcon {
                        background: $primary;
                        border-radius: 40px;
                        color: $white;
                        display: flex;
                        height: 32px;
                        width: 32px;
                        align-items: center;
                        justify-content: center;

                        &:hover {
                            background: $darkgold;
                        }
                    }
                }

            }
        }

        .MuiPaper-root {
            background: transparent;
            border-bottom: 1px solid #E0E0E0;
            box-shadow: none;
            // margin-bottom: 16px;
            // margin-top: 16px;

            &:last-child {
                border-bottom: none;
            }

            &:before {
                display: none;
            }
        }

        .MuiAccordionSummary-root {
            padding: 0;
            min-height: 24px;
            // max-height: 44px;
            padding-bottom: 16px;

            &.Mui-expanded {
                min-height: 24px;
            }

            .MuiAccordionSummary-expandIconWrapper {
                color: rgba(155, 104, 23, 1);
                transform: none;
            }
        }

        .MuiAccordionSummary-content {
            margin: 0px;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: $black;

            &.Mui-expanded {
                margin: 0px;
            }

            p {
                @include fontSize(18px);
                font-family: 'JioType';
                line-height: 20px;
                font-weight: 500;
                letter-spacing: -0.48px;
                color: $darkgrey;
            }
        }

        .MuiAccordionDetails-root {
            padding: 0px 0px 16px;

            p {
                @include fontSize(14px);
                font-family: 'JioType';
                color: #7a7a7a;
                letter-spacing: -0.07px;
                font-weight: 500;
                line-height: 20px;
            }
        }
    }

    hr {
        border: none;
        border-top: 1px solid rgba(201, 175, 222, 0.30);
    }

    .bottomPart {
        margin-top: 12px;
        border-top: 1px solid rgba(201, 175, 222, 0.30);
        padding: 12px;

        p {
            @include fontSize(12px);
            line-height: 16px;
            font-weight: 500;
            color: $lightgrey;
        }
    }
}