@import "../../../SCSS/Mixins.scss";

.faq {
    margin-bottom: 8px;
    font-family: 'JioType';

    .MuiPaper-root {
        background: transparent;
        // @include box-shadow(0px 2px 0px #E3EDFF);
        // @include border-radius(12px!important);
        border-bottom: 1px solid #E0E0E0;
        box-shadow: none;
        margin-bottom: 16px;
        margin-top: 16px;

        &:last-child {
            border-bottom: none;
        }

        &:before {
            display: none;
        }
    }

    .MuiAccordionSummary-root {
        padding: 0;
        min-height: 24px;
        // max-height: 44px;
        padding-bottom: 12px;

        &.Mui-expanded {
            min-height: 24px;
            // max-height: 44px;
        }

        .MuiAccordionSummary-expandIconWrapper {
            color: rgba(155, 104, 23, 1);
            transform: none;
        }
    }

    .MuiAccordionSummary-content {
        margin: 0px;

        &.Mui-expanded {
            margin: 0px;
        }

        p {
            @include fontSize(18px);
            font-family: 'JioType';
            line-height: 20px;
            font-weight: 500;
            letter-spacing: -0.48px;
            color: $darkgrey;

            @media(max-width:899px) {
                @include fontSize(16px);
                line-height: 20px;
            }
        }
    }

    .MuiAccordionDetails-root {
        padding: 0px 0px 16px;

        p {
            @include fontSize(14px);
            font-family: 'JioType';
            color: #7a7a7a;
            letter-spacing: -0.07px;
            font-weight: 500;
            line-height: 20px;

            ul {
                padding-left: 16px;
                margin-top: 5px;
                li {
                    list-style: disc;
                    margin-bottom: 5px;
                    span {
                        font-weight: 700;
                        color: rgba($color: #000000, $alpha: 0.7);
                    }
                }

            }
        }

    }
}