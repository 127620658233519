@import "./../../../../SCSS/Mixins.scss";

.navbar {
    @include position(fixed, $top: 0px, $left: 0px);
    background: $lightMarigold20;
    width: 100%;
    z-index: 10;
    &.shadow {
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    ul {
        display: inline-flex;
        gap: 32px;
        margin-top: 6px;

        li {

            a {
                @include fontSize(14px);
                line-height: 24px;
                color: $warmgold;
                padding: 16px 0;
                font-weight: 500;
                display: inline-block;
                &:hover {
                    background: $lightMarigold20;
                    color: $primary;
                }
            }
            &.productLink {
                position: relative;
                a {
                    padding-left: 40px;
                    
                }
                &:hover {
                    .dropdownMenu {
                        display: inline-block;
                        opacity: 1;
                        visibility: visible;
                    }
                }
                .dropdownMenu {
                    position: absolute;
                    right: 0px;
                    top: 48px;
                    background: $white;
                    border-radius: 0px 0px 24px 24px;
                    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1);
                    width: 312px;
                    padding: 8px 0px;
                    transition: all 0.5s ease;
                    display: none;
                    opacity: 0;
                    overflow: hidden;
                    visibility: hidden;
                    a {
                        padding: 8px 16px;
                        text-align: left;
                        display: block;
                    }
                }
            }
        }
    }
    .user {
        background: $lightMarigold20;
        @include border-radius(40px);
        padding: 5px 6px;
        height: 32px;
        width: 32px;
        min-width: 32px;
        min-height: 32px;
        &:hover {
            background: $lightMarigold30;
        }
        img {
            width: 100%;
        }
    }
    .loginBtn {
        padding: 8px 16px;
        background: $warmgold;
        color: #fff;
        @include fontSize(12px);
        letter-spacing: -0.06px;
        line-height: 16px;
        font-weight: 700;
        font-family: 'JioType';
        text-transform: capitalize;
        border-radius: 50px;
        min-height: 40px;

        &:hover {
            background: $warmgold;
            color: #fff;
        }

        svg {
            margin-right: 8px;
        }
    }
}

.popoverMenu {
    .MuiPaper-elevation {
        // top: 70px !important;
        margin-top: 23px;
        border-radius: 0px 0px 24px 24px !important;
        min-width: 312px !important;
        box-shadow: none;

        .MuiList-root {
            .MuiMenuItem-root {
                @include fontSize(16px);
                font-family: 'jioType';
                line-height: normal;
                min-height: 40px;
                // border-bottom: 1px solid #d9d9d9;
                font-weight: 500;
                color: $warmgold;
                &:last-child {
                    border-bottom: none;
                }

                &:hover,
                :active {
                    color: $primary;
                }
            }
        }
    }
}