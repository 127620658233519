@import "./../../../../SCSS/Mixins.scss";

.footer {
    background: #F5F5F5;
    position: relative;
    
    .jioBand {
        background: url("../../../../../public/images/jio-band-with-logo.svg") no-repeat right 12px center/61px;
        position: absolute;
        top: 0px;
        left: 50%;
        height: 100%;
        width: 100%;
        max-width: 1178px;
        transform: translateX(-50%);
        z-index: 0;
    }
    p {
        letter-spacing: -0.08px;
    }
    .topPart {
        padding-top: 40px;
        position: relative;
        z-index: 2;
        h5 {
            color: $darkgrey;
            margin-bottom: 12px;
            font-weight: 900;
            @include fontSize(16px);
            letter-spacing: -0.48px;
        }

        ul {
            li {
                margin-bottom: 8px;
                &:last-child{
                    margin-bottom: 0;
                }
                a {
                    color: $lightgrey;
                    font-size: 16px;
                    line-height: 24px;
                    padding: 2px 0px;
                    display: inline-block;
                    font-weight: 500;
                    letter-spacing: -0.08px;
                    &:hover{
                        color: $primary;
                    }
                    &.socialMediaIcon {
                        background: $primary;
                        border-radius: 40px;
                        color: $white;
                        display: flex;
                        height: 32px;
                        width: 32px;
                        align-items: center;
                        justify-content: center;
                        &:hover {
                            background: $darkgold;
                        }
                    }
                }
                
            }
        }
    }
    hr{
        border: none;
        border-top: 1px solid rgba(201, 175, 222, 0.30);
    }
    .bottomPart{
        padding: 16px 0px;
        p{
            @include fontSize(12px);
            line-height: 14px;
            font-weight: 500;
            color: #595959;
        }
    }
    
}