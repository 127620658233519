@import "../../../../SCSS/Amaze/CommonSCSS/mixins.scss";

// Proposal Footer
.mProposalFooter {
  background: $white;
  box-shadow: 0px -4px 12px rgba($black, 0.1);
  padding: 8px;
  @include position(fixed, $bottom: 0px, $left: 0px);
  width: 100%;
  z-index: 9;
  .footerInner {
    // min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // .backStep {
  //     position: relative;
  //     color: $black;
  //     padding: 4px 0px;
  //     display: inline-flex;
  //     align-items: center;
  //     width: 120px;

  //     &:hover {
  //         svg {
  //             left: -4px;
  //         }
  //     }
  //     svg {
  //         height: 16px;
  //         position: relative;
  //         left: 0px;
  //         transition: all 0.3s ease;
  //     }
  // }
  // .ctaBtn {
  //     flex: 0 0 100%;
  //     max-width: 72%;
  //     text-align: center;
  // }
}
