@import "../../../../SCSS/Mixins.scss";
// Main Navbar
.mProposalNavbar {
  background: $lightMarigold20;
  box-shadow: 0 2px 8px #0000001a;
  @include position(fixed, $top: 0px, $left: 0px);
  padding: 12px;
  height: 44px;
  width: 100%;
  z-index: 991;
  margin-left: auto;
  margin-right: auto;
  .backStep {
    position: relative;
    color: $black;
    // padding: 4px 4px 4px 0px;
    // &:hover {
    //     svg {
    //         left: -4px;
    //     }
    // }
    svg {
      height: 16px;
      position: relative;
      left: 0px;
      top: 2px;
      // transition: all 0.3s ease;
    }
  }
  .MuiGrid2-container {
    max-width: 1360px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
