@import "../../../../../SCSS/Mixins.scss";

.featureTabs {
    background: $white;
    border-radius: 0px 0px 24px 24px;

    .MuiTabs-root {
        .MuiTabs-flexContainer {
            
            .MuiButtonBase-root {
                font-weight: 500;
                font-size: 16px;
                text-transform: inherit;
                height: 48px;
                min-width: 50%;

                &.Mui-selected {
                    background-color: $white;
                    color: $darkgrey;
                }
            }
        }

        .MuiTabs-indicator {
            background-color: $primary;
            border-radius: 12px;
            right: auto;
            left: 0px;
            height: 4px;
        }
    }

    .MuiPaper-root {
        box-shadow: none;
    }
    .MuiAccordionSummary-root {
        padding: 0px;
    }
    .MuiAccordionSummary-content {
        font-size: 16px;
        font-weight: 500;
        color: $darkgrey;
    }
    .MuiAccordionDetails-root {
        padding-left: 0px;
        padding-right: 0px;
    }
    .MuiAccordionSummary-expandIconWrapper {
        transition: none;
    }
}