@import "./Mixins.scss";

.cmsWrapper {
  padding-top: 122px;

  h2 {
    text-align: center;
  }

  @media only screen and (max-width: 899px) {
    padding-top: 64px;

    h2 {
      @include fontSize(24px);
      line-height: 28px;
      text-align: left;
    }

    h5 {
      @include fontSize(16px);
      line-height: 24px;
      text-align: left;
    }
  }

  .career_section {
    padding: 52px 0px;
    // background: $primary;

    .career_openingBox {
      background: #906729;
      padding-left: 50px;
      @include border-radius(40px);
      overflow: hidden;
      min-height: 324px;
      position: relative;
      z-index: 9;
      display: flex;
      gap: 32px;
      align-items: center;

      img {
        flex: 1;
      }

      &:after {
        @include position(absolute, $top: 0, $right: 40px);
        content: "";
        background: url("./../../public/images/jio-band.svg") no-repeat 0 0/100%;
        height: 100%;
        width: 81px;
        z-index: 2;
      }
    }
  }

  &.sectionSpacing {
    display: flex;
    flex-direction: column;
    gap: 80px;

    @media only screen and (max-width: 899px) {
      gap: 36px;
    }
  }

  .partner_box {
    margin: 0px 8px;
    @include border-radius(8px);
    border: 1px solid rgba(#000000, 0.1);

    img {
      width: 100%;

      // @media (max-width:767px) {
      //     max-width: 84px;
      // }
    }
  }

  .tableWrapper {
    overflow-x: auto;
    width: 100%;

    .prefferedTable {
      width: 100%;
      margin-top: 24px;

      tr {
        th {
          text-align: center;
          padding: 12px;
          @include fontSize(24px);
          line-height: 28px;
          font-weight: 900;
          color: #312109;
          background: $lightMarigold20;
          margin-bottom: 12px;

          @media only screen and (max-width: 768px) {
            @include fontSize(14px);
            line-height: 18px;
            padding: 8px;
          }

          &:first-child {
            text-align: left;
            border-left: 1px solid $lightMarigold20;
          }

          &:last-child {
            border-right: 1px solid $lightMarigold20;
            max-width: 286px;
          }

          &:nth-child(2) {
            border-left: 1px solid #e0e0e0;
            border-right: 1px solid #e0e0e0;
            max-width: 286px;
          }
        }

        td {
          @include fontSize(18px);
          line-height: 24px;
          font-weight: 500;
          color: $lightgrey;
          text-align: center;
          padding: 12px;
          min-height: 60px;
          height: 100%;
          border-bottom: 1px solid rgba(215, 215, 226, 1);

          @media only screen and (max-width: 768px) {
            @include fontSize(14px);
            line-height: 18px;
            padding: 8px;
          }

          &:first-child {
            text-align: left;
            border-left: 1px solid #e0e0e0;
          }

          &:last-child {
            border-right: 1px solid #e0e0e0;
            max-width: 286px;
          }

          &:nth-child(2) {
            border-left: 1px solid #e0e0e0;
            border-right: 1px solid #e0e0e0;
            max-width: 286px;
          }

          .success {
            border-left: 2.5px solid #25ab21;
            border-bottom: 2.5px solid #25ab21;
            height: 12px;
            width: 22px;
            transform: rotate(-45deg);
            margin: 0px auto;
            position: relative;
            top: -4px;

            @media only screen and (max-width: 768px) {
              border-left: 1.5px solid #25ab21;
              border-bottom: 1.5px solid #25ab21;
              height: 8px;
              width: 14px;
            }
          }

          .failed {
            height: 24px;
            width: 24px;
            margin: 0px auto;
            position: relative;

            &:before {
              position: absolute;
              transform: rotate(-45deg);
              content: "";
              background: #f50031;
              border-radius: 4px;
              height: 24px;
              width: 2.5px;
            }

            &:after {
              @extend :before;
              transform: rotate(45deg);
            }

            @media only screen and (max-width: 768px) {
              &:before,
              &:after {
                height: 16px;
                width: 1.5px;
              }
            }
          }
        }

        th,
        td {
          width: 28%;

          &:first-child {
            width: 47%;
          }

          @media (max-width: 500px) {
          }
        }
      }
    }
  }

  .cmsPages {
    margin-bottom: 40px;

    @media only screen and (max-width: 899px) {
      p,
      li {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.4rem;
        color: rgb(89, 89, 89);
      }

      h2 {
        @include fontSize(24px);
        line-height: 28px;
        text-align: left;
      }

      h3 {
        @include fontSize(20px);
        line-height: 24px;
      }

      h4 {
        @include fontSize(16px);
        line-height: 24px;
      }

      ul,
      ol {
        margin-left: 16px;

        li {
          margin-bottom: 8px;

          ul,
          ol {
            margin-left: 12px;
            margin-top: 8px;
          }
        }
      }
    }

    h2 {
      margin: 24px 0px;
    }

    h3,
    h4 {
      margin-bottom: 16px;
    }

    b {
      font-weight: 900 !important;
    }

    a {
      color: #906729;
    }

    p,
    ul,
    ol {
      margin-bottom: 20px;
      text-align: justify;

      @media only screen and (max-width: 899px) {
        margin-bottom: 12px;
      }
    }

    ul {
      li {
        list-style-type: disc;
        text-align: justify;
      }
    }

    ul,
    ol {
      margin-left: 20px;

      li {
        margin-bottom: 12px;

        @media only screen and (max-width: 899px) {
          margin-bottom: 8px;
        }

        ul,
        ol {
          margin-left: 12px;
          margin-top: 12px;
        }
      }
    }

    .tableContainer {
      overflow-x: auto;
      margin-bottom: 20px;

      table {
        width: 100%;
        min-width: 599px;
        border: 1px solid #292929;
        border-collapse: collapse;

        tr {
          th,
          td {
            padding: 8px;
            border-bottom: 1px solid #292929;
            border-right: 1px solid #292929;
            text-align: center;
            @media (max-width: 899px) {
              @include fontSize(14px);
            }
          }

          th {
            font-weight: 900;
          }

          td {
            &:first-child {
              font-weight: 900;
            }
          }
        }

        &.irda_1 {
          tr {
            &:first-child {
              background: #fef7ef;
            }

            th,
            td {
              width: 40%;

              &:nth-child(2) {
                width: 20%;
              }
            }

            td {
              &:first-child {
                font-weight: 400;
              }
            }
          }
        }
        &.irda_2 {
          tr {
            &:first-child {
              background: #f5f5f5;
            }
            &:nth-child(2) {
              background: #fef7ef;
            }

            th,
            td {
              width: 40%;

              &:nth-child(2) {
                width: 20%;
              }
            }

            td {
              &:first-child {
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
}
