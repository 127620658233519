@import "./Mixins.scss";

.quoteWrapperMobile {
  padding-top: 64px;
  min-height: 100vh;
  position: relative;

  .addonStrip {
    padding: 8px 16px;
    background: rgba(20, 20, 20, 0.8);
    @include position(fixed, $bottom: 24px, $left: 24px, $right: 24px);
    width: calc(100% - 48px);
    @include border-radius(100px);
    display: flex;
    justify-content: space-between;
    z-index: 9;

    .addonLink {
      max-width: 120px;
      width: 100%;
      padding: 8px 4px;
      font-size: (16px);
      line-height: 24px;
      font-weight: 500;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 24px;
        width: 24px;
        margin-right: 8px;
      }
    }

    .singleAddonLink {
      @extend .addonLink;
      max-width: 100%;
    }
  }

  .nonterm_bottom_nav {
    @include position(fixed, $bottom: 24px, $left: 24px, $right: 24px);
    width: calc(100% - 48px);
    @include border-radius(24px);
    display: flex;
    justify-content: space-between;
    gap: 12px;
    z-index: 9;

    .stripBox {
      padding: 8px 8px;
      background: rgba(20, 20, 20, 0.8);
      @include border-radius(24px);

      .addonLink {
        width: 100%;
        padding: 8px 4px;
        font-size: (16px);
        line-height: 24px;
        font-weight: 500;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 24px;
          width: 24px;
          margin-right: 8px;
        }
      }
    }
  }
}
